import './App.css';

function App() {
  return (
    <div className="App">
      <iframe
        data-openreplay-capture
        title="exam"
        src="https://docs.google.com/forms/d/e/1FAIpQLScBXRyTybSJNC1rYFDREulBPBoThYHXB8XzL9wljnIEwAdl-A/viewform?embedded=true"
        width={window.innerWidth}
        height={window.innerHeight}
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      >
        Loading…
      </iframe>
    </div>
  );
}

export default App;
